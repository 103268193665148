
<template>
  <b-alert v-if="error.length > 0" variant="danger" :show="true">
    <h4 class="alert-heading">Error obteniendo datos</h4>
    <div class="alert-body">
      Ocurrio un error al obtener los datos [{{ error }}]
    </div>
  </b-alert>
  <b-row v-else>
    <b-col cols="12" md="8">
      <b-card no-body>
        <b-card-body class="p-3">
          <h2 class="mb-2">Nueva Bodega</h2>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Nombre Bodega" label-for="vi-nombrebodega">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-nombrebodega"
                    v-model="warehouse.nombreBodega"
                    placeholder=""
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="ID Código Contifico"
                label-for="vi-idcodigocontifico"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-idcodigocontifico"
                    v-model="warehouse.idCodigoContifico"
                    placeholder="B001"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="Código Contifico"
                label-for="vi-codigocontifico"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-codigocontifico"
                    v-model="warehouse.codigoContifico"
                    placeholder="xyArb2rr1t30byR7"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="ID Bodega Contifico"
                label-for="vi-idbodegacontifico"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-idbodegacontifico"
                    type="number"
                    min="0"
                    v-model="warehouse.idCodigoBodegaContifico"
                    placeholder="81395"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label="Telefono" label-for="vi-telefono">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-telefono"
                    v-model="warehouse.telefono"
                    placeholder=""
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label="Secuencia" label-for="vi-secuencia">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-secuencia"
                    v-model="warehouse.bg_Secuencua"
                    placeholder=""
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label="POS Token API" label-for="vi-postokenapi">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-postokenapi"
                    v-model="warehouse.pos_token_contifico"
                    placeholder=""
                  />
                </b-input-group>
                <b-form-text>Token del POS asignado de contifico</b-form-text>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="Establecimiento"
                label-for="vi-establecimiento"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-establecimiento"
                    v-model="warehouse.establecimiento"
                    placeholder=""
                  />
                </b-input-group>
                <b-form-text>Establecimiento del POS asignado de contifico</b-form-text
                >
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="Punto de emisión"
                label-for="vi-puntoemision"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-puntoemision"
                    v-model="warehouse.puntoEmision"
                    placeholder=""
                  />
                </b-input-group>
                <b-form-text
                  >Punto Emisión del POS asignado de contifico</b-form-text
                >
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="Mensaje bodega cerrada"
                label-for="vi-msjcerrarbodega"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-msjcerrarbodega"
                    v-model="warehouse.mensaje_cerrado"
                    placeholder=""
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-row>
                <b-col cols="4" md="4">
                  <b-form-group
                    label="Codigo Local"
                    class="m-0"
                    label-for="vi-cod-local"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="vi-cod-local"
                        v-model="warehouse.bP_codLocal"
                        placeholder=""
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="4" md="4">
                  <b-form-group class="m-0" label="MID" label-for="vi-mid">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="vi-mid"
                        v-model="warehouse.bP_Mid"
                        placeholder=""
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="4" md="4">
                  <b-form-group class="m-0" label="TID" label-for="vi-tid">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="vi-tid"
                        v-model="warehouse.bP_Tid"
                        placeholder=""
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-text>Datos proporcionados por El Rosado</b-form-text>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" md="12" class="mt-1">
              <b-form-group
                label="Token Gacela Market"
                label-for="vi-tokengacelamarket"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-tokengacelamarket"
                    v-model="warehouse.tokenGacela"
                    placeholder=""
                  />
                </b-input-group>
                <b-form-text
                  >Manejo de envio con terceros. Market y Tiendas en
                  Eats</b-form-text
                >
              </b-form-group>
            </b-col>

            <b-col cols="12 mt-2">
              <b-button
                class="mr-1"
                @click="saveData"
                variant="primary"
                :disabled="loading"
              >
                <b-spinner v-if="loading" small label="Loading..." />
                <div v-else>Guardar</div>
              </b-button>
            </b-col>
          </b-row>
        </b-card-body></b-card
      >
    </b-col>

    <b-col cols="12" md="4">
      <b-card title="Opciones">
        <b-form-checkbox v-model="warehouse.statusBod" switch>
          Activa
        </b-form-checkbox>
        <b-form-checkbox v-model="warehouse.bg_Bloqueo" switch>
          Bloqueada
        </b-form-checkbox>
        <b-form-checkbox v-model="warehouse.inmediata" switch>
          Permite compras inmediatas
        </b-form-checkbox>
        <b-form-checkbox v-model="warehouse.programada" switch>
          Permite compras programadas
        </b-form-checkbox>
        <b-form-checkbox v-model="warehouse.market" switch>
          Acepta Gacela Market
        </b-form-checkbox>
        <b-form-checkbox v-model="warehouse.eats" switch>
          Acepta Gacela Asociados
        </b-form-checkbox>
        <b-form-radio-group
          v-model="warehouse.bg_type"
          :options="bodegaTypes"
          class="mb-3"
          value-field="item"
          text-field="name"
        ></b-form-radio-group>
        <horarios
          ref="horario"
          :editForm="false"
          :data="warehouse.bg_horario"
          :warehouseID="warehouse.idBodega"
        />
      </b-card>
      <sectores :warehouseID="warehouse.idBodega" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BButton,
  BSpinner,
  BCardBody,
  BFormText,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BFormRadioGroup
} from "bootstrap-vue";
import Sectores from "./Sectores.vue";
import Horarios from "./HorariosV2.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BButton,
    BSpinner,
    Sectores,
    Horarios,
    BCardBody,
    BFormText,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardActions,
    BFormCheckbox,
    ToastificationContent,
    BFormRadioGroup,
  },
  data() {
    return {
      error: "",
      loading: false,
      bodegaTypes: [
        { item: 'SEASY', name: 'Super Easy' },
        { item: 'BOX', name: 'Box' }
      ],
      warehouse: {
        nombreBodega: '',
        idCodigoContifico: '',
        codigoContifico: '',
        idCodigoBodegaContifico: 0,
        telefono: '',
        bg_Secuencua: '0',
        pos_token_contifico: '',
        establecimiento: '',
        puntoEmision: '',
        mensaje_cerrado: '',
        bP_codLocal: '',
        bP_Mid: '',
        bP_Tid: '',
        tokenGacela: '',
        idBodega: 0,
        bg_horario: '',
        bg_type: 'SEASY',
        statusBod: false,
        bg_Bloqueo: false,
        inmediata: false,
        programada: false,
        market: false,
        eats: false,
      },
      warehouseID: 0,
    };
  },
  methods: {
    saveData(e) {
      e.preventDefault();
      this.loading = true;
      this.warehouse.otp = "I";
      this.warehouse.empresa = 2;
      this.warehouse.gacelaEats = this.warehouse.eats ? "1" : "0";
      this.warehouse.gacelaMarket = this.warehouse.market ? "1" : "0";
      this.warehouse.bg_horario = JSON.stringify(this.$refs.horario.horarios);
      this.warehouse.tokenSesion = getAuthToken();
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/Bodegas",
          body: JSON.stringify(this.warehouse),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "CheckIcon",
                text: "Se registró correctamente la bodega.",
                title: "Éxito",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: res.data.mensaje,
                title: "Error",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: e.message,
              title: "Error",
              variant: "danger",
            },
          });
        });
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>